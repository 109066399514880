import { useState } from "react"

export const usePhotoGallery = (images=[]) => {

    const [ opened, setOpened ] = useState(false)
    const [ currentImage, setCurrentImage ] = useState({})

    const onOpenGallery = (imageId) => {
        setCurrentImage(images.find(img => img.id === imageId))
        console.log(imageId)
        setOpened(true)
    }

    const onSlideRight = () => {

        const curIdx = images.findIndex(img => img.id === currentImage.id)

        if(curIdx+1 === images.length) {
            setCurrentImage(images[0])
        } else {
            setCurrentImage(images[curIdx+1])
        }
    }

    const onSlideLeft = () => {

        const curIdx = images.findIndex(img => img.id === currentImage.id)

        if(curIdx === 0) {
            setCurrentImage(images[images.length-1])
        } else {
            setCurrentImage(images[curIdx-1])
        }
    }

    const onCloseGallery = () => {
        setOpened(false)
        setCurrentImage({})
    }

    return [ opened, currentImage, onOpenGallery, onSlideRight, onSlideLeft, onCloseGallery ]
}