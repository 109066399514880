export const usePrintPage = () => {

    const doPrint = () => {
        try {
            window.print()
        } catch (error) {
            alert(error.message)
        }
    }

    return [ doPrint ]
}