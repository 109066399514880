import React from 'react'
import style from './Footer.module.sass'
import { routerConfig } from 'shared/configs'

export const Footer = () => {
    return(
        <footer className={style.footer}>
            <div className={style.textItem}>w.ppfunion.com — {new Date().getFullYear()}</div>
            <a href={`/${routerConfig.policy}`} target={'_blanc'} className={style.textItem}>Политика конфиденциальности</a>
        </footer>
    )
}