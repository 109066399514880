import React, { useState } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import { CODE_REGISTER_PAGE_URL } from 'shared/utils/constants'
import style from './Layout.module.sass'
import { MainBtn } from 'shared/buttons'
import { usePrintPage } from 'shared/hooks'
import { ReactComponent as QrCodeBorderSvg} from 'assets/images/qr_code_border_big.svg'
import html2pdf from 'html2pdf.js'

export const Layout = ({ code }) => {

    const [ printPage ] = usePrintPage()
    const [ genInProcess, setGenInProcess ] = useState(false)
    
    const GeneratePDF = () => {
        setGenInProcess(true)
        const element = document.getElementById('toprint')
        const opt = {
            margin:       0,
            filename:     `Код_гарантии_${code}.pdf`,
            image:        { type: 'jpeg', quality: 1 },
            html2canvas:  { scale: 5, dpi: 600, letterRendering: true },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' },
            DisablePdfCompression: 1,
        }

        html2pdf().set(opt).from(element).save()
    }

    return(
        <div className={style.wrapper}>
            {!genInProcess
            ?
            <MainBtn type={'button'} disabled={genInProcess} onClick={() => GeneratePDF()}>
                Скачать QR-код
            </MainBtn>
            :
            <div className={style.genInfo}>
                Файл с qr-кодом формируется и начнёт скачиваться автоматически. На это может потребоваться некоторое время.
            </div>
            }
            <div className={style.qrcodeWrapper} id='toprint'>
                <div className={style.qrcode}>
                    <QRCodeSVG
                        value={`${CODE_REGISTER_PAGE_URL}${code}`}
                        size={300}
                        />
                    <div className={style.qrCodeBorder}>
                        <QrCodeBorderSvg/>
                    </div>
                </div>
                <div className={style.code}>
                    {code}
                </div>
                <div className={style.codeInfo}>
                    <span>
                    Отсканируйте QR-код или перейдите по адресу w.ppfunion.com/register и зарегистрируйте официальную гарантию производителя сразу после установки плёнки.
                    </span>
                    <span>
                    Остались вопросы? Контакты для связи с производителем:
                    </span>
                    <span>
                    <b>ppfunion.com</b><br />
                    <b>+7 (991) 135-8157</b><br />
                    <b>+7 (991) 135-8156</b><br />
                    <b>provide.vseplenki@gmail.com</b>
                    </span>
                </div>
            </div>
        </div>
    )
}