class RouterConfig {
    panel = 'panel'
    codes = 'codes'
    qrGen = 'qr/:code'
    qr = 'qr'
    certificates = 'certs'
    certificateGen = 'cert/:cert'
    cert = 'cert'
    codeRegister = 'register'
    auth = 'auth'
    token = 'token'
    accessDenied = 'access-denied'
    certficateChecker = 'certificate-checker'
    policy = 'policy'
}


export const routerConfig = new RouterConfig()