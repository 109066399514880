import { Layout } from 'app/layout'
import { Route, Routes } from 'react-router-dom'
import { CodeQRGenerationModule } from 'modules/codeGenerator'
import { SuggestToken } from 'pages/auth'
import { Index } from 'pages/index'
import { Panel } from 'pages/panel'
import { WarrantyRegistration } from 'pages/wrrantyRegistration'
import { AuthorizedRoute, ProtectedRoute } from 'shared/routes'
import { AccessDenied } from 'pages/accessDenied'
import { Certs } from 'pages/certs'
import { CertGeneratorModule } from 'modules/certificateGenerator'
import { routerConfig } from 'shared/configs'
import { CertChecker } from 'pages/certChecker'
import { Policy } from 'pages/policy'

export const AppRouter = () => {
    return(
        
            <Routes>
                <Route path={'/'} element={<Layout/>}>

                    <Route index element={<AuthorizedRoute><Index/></AuthorizedRoute>}/>
                    <Route path={routerConfig.panel} element={<ProtectedRoute/>}>
                        <Route path={routerConfig.codes} element={<Panel/>}/>
                        <Route path={routerConfig.qrGen} element={<CodeQRGenerationModule/>}/>
                        <Route path={routerConfig.certificates} element={<Certs/>}/>
                        <Route path={routerConfig.certificateGen} element={<CertGeneratorModule/>}/>
                    </Route>
                    <Route path={routerConfig.codeRegister}>
                        <Route index element={<WarrantyRegistration/>}/>
                        <Route path={':code'} element={<WarrantyRegistration/>}/>
                    </Route>
                    <Route path={`${routerConfig.certficateChecker}/:cert`} element={<CertChecker/>}/>
                    <Route path={`${routerConfig.policy}`} element={<Policy/>}/>
                    <Route path={routerConfig.auth}>
                        <Route path={routerConfig.token} element={<SuggestToken/>}/>
                    </Route>
                    <Route path={routerConfig.accessDenied} element={<AccessDenied/>}/>

                </Route>
            </Routes>
        
    )
}