export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
})

export const mapBase64ToRender = async (photoFiles) => {
    let data = await Promise.all(photoFiles.map(async (el, index) => {
        let base64Blob = await toBase64(el)
        return {index, blob: base64Blob}
    }))
    return data
}