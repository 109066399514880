import React, { useEffect, useState } from "react"
import style from './Layout.module.sass'
import { MainBtn } from "shared/buttons"
import { QRCodeSVG } from "qrcode.react"
import { RANK_CERT_IMAGES } from "shared/utils/constants"
import { routerConfig } from "shared/configs"
import { GetCertRankName } from "shared/utils/getCertRankName"
import { Fullname } from "../Fullname/Fullname"
import html2pdf from 'html2pdf.js'
import { ReactComponent as QrCodeFrameSvg } from 'assets/images/qr_code_border_small.svg'

export const Layout = ({ certificateData }) => {
    
    const [ certBlob, setCertBlob ] = useState(null)
    const [ genInProcess, setGenInProcess ] = useState(false)
    
    const GeneratePDF = () => {
        setGenInProcess(true)
        let element = document.getElementById('toprint')
        let opt = {
            margin:       0,
            filename:     `Сертификат_#${certificateData.id}_${certificateData.surname}_${certificateData.name}_${certificateData.patronymic}.pdf`,
            image:        { type: 'jpeg', quality: 1 },
            html2canvas:  { scale: 4, dpi: 600, letterRendering: true },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' },
            DisablePdfCompression: 1,
        }
        html2pdf().set(opt).from(element).save()
    }

    const toDataUrl = async function (url, callback) {
        //Convert to base64
        return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest()
        xhr.onload = function () {
            var reader = new FileReader()
            reader.onloadend = function () {
            resolve(reader.result)
            }
            reader.readAsDataURL(xhr.response)
        }
        xhr.onerror = () => {
            reject({
            status: this.status,
            statusText: xhr.statusText,
            })
        }
        xhr.open("GET", url)
        xhr.responseType = "blob"
        xhr.send()
        })
    }

    const getImage = async (certRank) => {
        const dataTest = await toDataUrl(RANK_CERT_IMAGES[certRank])
        setCertBlob(dataTest)
    }

    useEffect(() => {
        getImage(certificateData.certRank)
    }, [])

    return(
        <div className={style.wrapper}>
            <div className={style.certFrameWrapper}>
                <div className={style.certFrame} id="toprint">
                    {certBlob
                    ?
                    <img src={certBlob} alt=""/>
                    : null
                    }

                    <div className={style.qrCodeWrapper}>
                        <div className={style.qrCodeContainer}>
                            <div className={style.qrCode}>
                                <QRCodeSVG
                                    value={`https://w.ppfunion.com/${routerConfig.certficateChecker}/${certificateData.id}`}
                                    size={111}
                                    />
                            </div>
                            <QrCodeFrameSvg/>
                        </div>
                    </div>
                    <Fullname
                        certRank={certificateData.certRank}
                        fullname={`${certificateData.surname} ${certificateData.name} ${certificateData.patronymic}`}
                    />
                    
                </div>
            </div>
            <div className={style.cert}>
                ID — {certificateData.id}
            </div>
            <div className={style.certInfo}>
                <div className={style.certInfo__item}>
                    {`Мастер: ${certificateData.surname} ${certificateData.name} ${certificateData.patronymic}`}
                </div>
                <div className={style.certInfo__item}>
                    Грейд сертификата: <GetCertRankName rankId={certificateData.certRank}/>
                </div>
            </div>

            {!genInProcess
                ?
                <MainBtn disabled={certBlob ? false : true} onClick={() => GeneratePDF()}>
                    {certBlob ? 'Скачать сертификат' : 'Подготовка сертификата...'}
                </MainBtn>
                :
                <div className={style.genInfo}>
                    Файл с сертификатом формируется и начнёт скачиваться автоматически. На это может потребоваться некоторое время.
                </div>
                }
            
        </div>

        
    )
}