import React from 'react'
import style from './Layout.module.sass'

export const Layout = () => {
    return(
        <div className={style.policy}>
            <h1 className={style.mainTitle}>
                Политика конфиденциальности
            </h1>
            <p className={style.block}>
                Настоящая Политика конфиденциальности персональных данных (далее — Политика конфиденциальности) действует в отношении всей информации, которую сайт https://w.ppfunion.com/, на котором размещен текст этой Политики конфиденциальности, может получить о Пользователе, а также любых программ и продуктов, размещенных на нём.
            </p>
            <h2 className={style.secondTitle}>
                1. Определение терминов
            </h2>
            <p className={style.block}>
                1.1 В настоящей Политике конфиденциальности используются следующие термины:
            </p>
            <p className={style.block}>
                1.1.1. «Администрация сайта» – уполномоченные сотрудники на управления сайтом https://w.ppfunion.com/, действующие от его имени, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
            </p>
            <p className={style.block}>
                1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
            </p>
            <p className={style.block}>
                1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
            </p>

            <p className={style.block}>
                1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Администрацией сайта требование не допускать их умышленного распространения без согласия субъекта персональных данных или наличия иного законного основания.
            </p>
            <p className={style.block}>
                1.1.5. «Пользователь сайта (далее Пользователь)» – лицо, имеющее доступ к сайту, посредством сети Интернет и использующее данный сайт для своих целей.
            </p>
            <p className={style.block}>
                1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
            </p>
            <p className={style.block}>
                1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
            </p>

            <h2 className={style.secondTitle}>
                2. Общие положения
            </h2>
            
            <p className={style.block}>
                2.1. Использование Пользователем сайта означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.
            </p>
            <p className={style.block}>
                2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта.
            </p>
            <p className={style.block}>
                2.3.Настоящая Политика конфиденциальности применяется только к данному сайту. Администрация сайта не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на данном сайте.
            </p>
            <p className={style.block}>
                2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта.
            </p>

            <h2 className={style.secondTitle}>
                3. Предмет политики конфиденциальности
            </h2>
            
            <p className={style.block}>
            3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта по умышленному неразглашению персональных данных, которые Пользователь предоставляет по разнообразным запросам Администрации сайта (например, при регистрации на сайте, оформлении заказа, подписки на уведомления и т.п).
            </p>
            <p className={style.block}>
            3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения специальных форм на Сайте и обычно включают в себя следующую информацию:
            </p>
            <p className={style.block}>
            3.2.1. фамилию, имя, отчество Пользователя;
            </p>
            <p className={style.block}>
            3.2.2. контактный телефон Пользователя;
            </p>
            <p className={style.block}>
            3.2.3. Данные о транспортном средстве Пользователя;
            </p>
            <p className={style.block}>
            3.3. Администрация сайта также принимает усилия по защите Персональных данных, которые автоматически передаются в процессе посещения страниц сайта: IP адрес; информация из cookies; информация о браузере (или иной программе, которая осуществляет доступ к сайту); время доступа; посещенные адреса страниц; реферер (адрес предыдущей страницы) и т.п.
            </p>
            <p className={style.block}>
            3.3.1. Отключение cookies может повлечь невозможность доступа к сайту.
            </p>
            <p className={style.block}>
            3.3.2. Сайт осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем, для контроля корректности проводимых операций.
            </p>
            <p className={style.block}>
            3.4. Любая иная персональная информация не оговоренная выше (история покупок, используемые браузеры и операционные системы и т.д.) не подлежит умышленному разглашению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.
            </p>

            <h2 className={style.secondTitle}>
                4. Цели сбора персональной информации пользователя
            </h2>
            <p className={style.block}>
                4.1. Персональные данные Пользователя Администрация сайта может использовать в целях:
            </p>
            <p className={style.block}>
            4.1.1. Идентификации Пользователя, зарегистрированного на сайте, для оформления заказа и (или) заключения Договора.
            </p>
            <p className={style.block}>
            4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам сайта.
            </p>
            <p className={style.block}>
            4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования сайта, оказания услуг, обработка запросов и заявок от Пользователя.
            </p>
            <p className={style.block}>
            4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
            </p>
            <p className={style.block}>
            4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
            </p>
            <p className={style.block}>
            4.1.6. Создания учетной записи для совершения покупок, если Пользователь дал согласие на создание учетной записи.
            </p>
            <p className={style.block}>
            4.1.7. Уведомления Пользователя сайта о состоянии Заказа.
            </p>
            <p className={style.block}>
            4.1.8. Обработки и получения платежей, подтверждения налога или налоговых льгот, оспаривания платежа, определения права на получение кредитной линии Пользователем.
            </p>
            <p className={style.block}>
            4.1.9. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием сайта.
            </p>
            <p className={style.block}>
            4.1.10. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени сайта или от имени партнеров сайта.
            </p>
            <p className={style.block}>
            4.1.11. Осуществления рекламной деятельности с согласия Пользователя.
            </p>
            <p className={style.block}>
            4.1.12. Предоставления доступа Пользователю на сторонние сайты или сервисы партнеров данного сайта с целью получения их предложений, обновлений или услуг.
            </p>

            <h2 className={style.secondTitle}>
            5. Способы и сроки обработки персональной информации
            </h2>
            <p className={style.block}>
                5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.
            </p>
            <p className={style.block}>
                5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно в целях выполнения заявок Пользователя, оформленных на сайте, в рамках Договора публичной оферты.
            </p>
            <p className={style.block}>
                5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти только по основаниям и в порядке, установленным действующим законодательством.
            </p>

            <h2 className={style.secondTitle}>
            6. Обязательства сторон
            </h2>
            <p className={style.block}>
            6.1. Пользователь обязуется:
            </p>
            <p className={style.block}>
            6.1.1. Предоставить корректную и правдивую информацию о персональных данных, необходимую для пользования сайтом.
            </p>
            <p className={style.block}>
            6.1.2. Обновить или дополнить предоставленную информацию о персональных данных в случае изменения данной информации.
            </p>
            <p className={style.block}>
            6.1.3. Принимать меры для защиты доступа к своим конфиденциальным данным, хранящимся на сайте.
            </p>
            <p className={style.block}>
            6.2. Администрация сайта обязуется:
            </p>
            <p className={style.block}>
            6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.
            </p>
            <p className={style.block}>
            6.2.2. Не разглашать персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.
            </p>
            <p className={style.block}>
            6.2.3. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.
            </p>


            <h2 className={style.secondTitle}>
            7. Ответственность сторон
            </h2>
            <p className={style.block}>
            7.1. Администрация сайта несёт ответственность за умышленное разглашение Персональных данных Пользователя в соответствии с действующим законодательством, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.
            </p>
            <p className={style.block}>
            7.2. В случае утраты или разглашения Персональных данных Администрация сайта не несёт ответственность, если данная конфиденциальная информация:
            </p>
            <p className={style.block}>
            7.2.1. Стала публичным достоянием до её утраты или разглашения.
            </p>
            <p className={style.block}>
            7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.
            </p>
            <p className={style.block}>
            7.2.3. Была получена третьими лицами путем несанкционированного доступа к файлам сайта.
            </p>
            <p className={style.block}>
            7.2.4. Была разглашена с согласия Пользователя.
            </p>
            <p className={style.block}>
            7.3. Пользователь несет ответственность за правомерность, корректность и правдивость предоставленной Персональных данных в соответствии с действующим законодательством.
            </p>


            <h2 className={style.secondTitle}>
            8. Разрешение споров
            </h2>
            <p className={style.block}>
            8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).
            </p>
            <p className={style.block}>
            8.2 Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
            </p>
            <p className={style.block}>
            8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством.
            </p>
            <p className={style.block}>
            8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство.
            </p>


            <h2 className={style.secondTitle}>
            9. Дополнительные условия
            </h2>
            <p className={style.block}>
            9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.
            </p>
            <p className={style.block}>
            9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.
            </p>
        </div>
    )
}