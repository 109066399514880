import React, { useRef, useState } from 'react'
import { IMAGES_DIRECTORY } from 'shared/utils/constants'
import style from './Layout.module.sass'
import cn from 'classnames'
import { ReactComponent as ArrowSvg } from 'assets/images/arrow_down.svg'
import { ReactComponent as CloseSvg } from 'assets/images/close.svg'
import { useOutsideClickHandler } from 'shared/hooks'

export const Layout = ({ 
    opened=false,
    currentImage={},
    onSlideRight,
    onSlideLeft,
    closeGallery=() => {}
}) => {

    // images: []
    // id
    // path

    // const [ outsideRef ] = useOutsideClickHandler(closeGallery)

    if(opened) {
        return(
            <div className={style.gallery}>
                <button onClick={() => closeGallery()} className={style.closeBtn} type="button">
                    <CloseSvg/>
                </button>
                <div className={style.content}>
                    <button type="button" className={cn(style.toggleBtn, style.toggleBtn_left)} onClick={() => onSlideLeft()}>
                        <ArrowSvg/>
                    </button>
                    <img className={cn(
                            style.image,
                            {[style.showImage]: true}
                        )}
                        onLoad={() => {}}
                        src={IMAGES_DIRECTORY+currentImage.path} alt=""
                        />
                    <button type="button" className={cn(style.toggleBtn, style.toggleBtn_right)} onClick={() => onSlideRight()}>
                        <ArrowSvg/>
                    </button>

                    {/* {images?.map(img => 
                        <img className={cn(
                            style.image,
                            {[style.showImage]: img.id === showImage}
                        )}
                        key={img.id}
                        onLoad={() => {}}
                        src={IMAGES_DIRECTORY+img.path} alt=""
                        />
                    )} */}
                </div>
            </div>
        )
    } else {
        return(<></>)
    }
}