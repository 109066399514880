import React from 'react'
import style from './Layout.module.sass'
import { StatusNameColored } from 'shared/statusName'
import { MainBtn, SecondBtn } from 'shared/buttons'
import printSvg from 'assets/images/print.svg'
import { Content } from '../Content/Content'
import { CODE_STATUS_INFO } from 'shared/utils/constants'
import { routerConfig } from 'shared/configs'
import { GalleryModule } from 'modules/photoGallery'
import { usePhotoGallery } from 'modules/photoGallery/hook'

export const Layout = ({ data, codeId, activateCode, isCodeActivationPending }) => {

    const [ 
        opened, 
        currentImage, 
        onOpenGallery, 
        onSlideRight, 
        onSlideLeft,
        onCloseGallery
    ] = usePhotoGallery(data.code.status === 3 ? data.images : [])

    return(
        <>
            <div className={style.container}>
                <div className={style.status}>
                    <StatusNameColored status={data.code.status}/>
                </div>
                <div className={style.info}>{CODE_STATUS_INFO[data.code.status]}</div>
                <Content
                    data={data}
                    status={data.code.status}
                    onOpenGallery={onOpenGallery}
                    />
                {
                    data.code.status === 1
                    ?
                    <div className={style.btns}>
                        <SecondBtn 
                            icon={printSvg}
                            to={`/${routerConfig.panel}/${routerConfig.qr}/${data.code.code}`}
                            newTab={true}
                            >
                            Распечатать
                        </SecondBtn>
                        <MainBtn disabled={isCodeActivationPending} onClick={() => activateCode(codeId)}>
                            Активировать
                        </MainBtn>
                    </div>
                    :
                    null
                }
                
            </div>
            <GalleryModule
                opened={opened} 
                currentImage={currentImage}
                onSlideRight={onSlideRight}
                onSlideLeft={onSlideLeft}
                closeGallery={onCloseGallery}
                />
        </>
    )
}