import React, { useState } from 'react'
import style from './RegisteredImage.module.sass'
import { IMAGES_DIRECTORY } from 'shared/utils/constants'
import { ReactComponent as ExpandSvg } from 'assets/images/expand_content.svg'
import cn from 'classnames'

export const RegisteredImage = ({ img, onOpenGallery }) => {

    const [ isLoaded, setIsLoaded ] = useState(false)

    const onImageLoad = () => {
        setIsLoaded(true)
    }

    return(
        <div className={style.imageContainer}>
            <div onClick={() => onOpenGallery(img.id)} className={cn(
                    style.image,
                    {[style.imageLoaded]: isLoaded}
                )}>
                <img onLoad={() => onImageLoad()} src={IMAGES_DIRECTORY+img.path} alt="" />
                <div className={style.expand}>
                    <ExpandSvg/>
                </div>
            </div>
        </div>
    )
}